<template>
  <div class="login-container" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
    <el-card class="login-card">
      <h2>登录</h2>
      <el-form ref="loginForm" :model="loginForm" :rules="rules" label-width="100px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="loginForm.username" @keydown.enter.native="focusPassword"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" ref="passwordInput" v-model="loginForm.password"
            @keydown.enter.native="handleLogin"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleLogin">登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios'
import { Message } from 'element-ui';

export default {
  data() {
    return {
      backgroundImage: require('@/assets/login-background.jpg'), // 自定义背景图片
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    };
  },
  methods: {
    focusPassword() {
      this.$refs.passwordInput.focus();
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // 模拟登录请求
          axios.post('/energy/pcLogin', this.loginForm)
            .then((response) => {
              if (response.data.code === 200) {
                Message.success('登录成功');
                // 进行登录后的操作，例如跳转到主页
                const token = 'wx_session_id_adminYang';
                const expiry = new Date().getTime() + 6 * 60 * 60 * 1000; // 设置过期时间为6个小时
                this.$store.dispatch('updateToken', { token, expiry });
                this.jump('/ration')
              } else {
                Message.error(response.data.message);
              }
            })
            .catch(() => {
              Message.error('登录失败');
            });
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
    jump(url) {
      this.$router.push(url);
    }
  },
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.login-card {
  width: 400px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
}
</style>
