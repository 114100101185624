import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/LoginView.vue'
import store from '../store'; // 引入 Vuex Store
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: HomeView
  },
  {
    path: '/ration',
    name: 'ration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RationView.vue')
  }
]

const router = new VueRouter({
  routes
})
// 前置路由守卫
router.beforeEach((to, from, next) => {
  const token = store.getters.getToken;

  if (to.name !== 'login' && !token) {
    // 如果没有 token 并且目标路由不是登录页面，则重定向到登录页面
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router
