import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 全局状态
    token: null,
    tokenExpiry: null
  },
  mutations: {
    // 同步操作
    setToken(state, { token, expiry }) {
      state.token = token;
      state.tokenExpiry = expiry;
    },
    clearToken(state) {
      state.token = null;
      state.tokenExpiry = null;
    }
  },
  actions: {
    // 异步操作
    updateToken({ commit }, { token, expiry }) {
      commit('setToken', { token, expiry });
    },
    removeToken({ commit }) {
      commit('clearToken');
    }
  },
  getters: {
    // 计算属性
    getToken: state => state.token,
    isTokenExpired: state => {
      return state.tokenExpiry ? new Date().getTime() > state.tokenExpiry : true;
    }
  }
})
